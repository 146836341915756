import React from "react";
import "bulma/css/bulma.css";
import "../styles/global.css";

import ItemCard from "../components/item";

import SubCollection from "../components/subCollection";

import HeroCard from "../../static/images/_Eye Heart Soul/Hero Image/EyeHeartSoul.jpg";
import CarouselCard1 from "../../static/images/_Eye Heart Soul/Carousel/1.jpg";
import CarouselCard2 from "../../static/images/_Eye Heart Soul/Carousel/2.jpg";
import CarouselCard3 from "../../static/images/_Eye Heart Soul/Carousel/3.jpg";
import CarouselCard4 from "../../static/images/_Eye Heart Soul/Carousel/4.jpg";
import CarouselCard5 from "../../static/images/_Eye Heart Soul/Carousel/5.jpg";
import CarouselCard6 from "../../static/images/_Eye Heart Soul/Carousel/6.jpg";
import CarouselCard7 from "../../static/images/_Eye Heart Soul/Carousel/7.jpg";
import CarouselCard8 from "../../static/images/_Eye Heart Soul/Carousel/8.jpg";
import CarouselCard9 from "../../static/images/_Eye Heart Soul/Carousel/9.jpg";
import CarouselCard10 from "../../static/images/_Eye Heart Soul/Carousel/10.jpg";
import CarouselCard11 from "../../static/images/_Eye Heart Soul/Carousel/11.jpg";
import CarouselCard12 from "../../static/images/_Eye Heart Soul/Carousel/12.jpg";
import CarouselCard13 from "../../static/images/_Eye Heart Soul/Carousel/13.jpg";
import CarouselCard14 from "../../static/images/_Eye Heart Soul/Carousel/14.jpg";
import CarouselCard15 from "../../static/images/_Eye Heart Soul/Carousel/15.jpg";
import CarouselCard16 from "../../static/images/_Eye Heart Soul/Carousel/16.jpg";
import CarouselCard17 from "../../static/images/_Eye Heart Soul/Carousel/17.jpg";
import CarouselCard18 from "../../static/images/_Eye Heart Soul/Carousel/18.jpg";
import CarouselCard19 from "../../static/images/_Eye Heart Soul/Carousel/19.jpg";
import Card1 from "../../static/images/_Eye Heart Soul/Cards/1.jpg";
import Card2 from "../../static/images/_Eye Heart Soul/Cards/2.jpg";
import Card3 from "../../static/images/_Eye Heart Soul/Cards/3.jpg";
import Card4 from "../../static/images/_Eye Heart Soul/Cards/4.jpg";
import Card5 from "../../static/images/_Eye Heart Soul/Cards/5.jpg";
import Card6 from "../../static/images/_Eye Heart Soul/Cards/6.jpg";
import Card7 from "../../static/images/_Eye Heart Soul/Cards/7.jpg";
import Card8 from "../../static/images/_Eye Heart Soul/Cards/8.jpg";
import Card9 from "../../static/images/_Eye Heart Soul/Cards/9.jpg";
const imgUrls = [
  `${CarouselCard1}`,
  `${CarouselCard2}`,
  `${CarouselCard3}`,
  `${CarouselCard4}`,
  `${CarouselCard5}`,
  `${CarouselCard6}`,
  `${CarouselCard7}`,
  `${CarouselCard8}`,
  `${CarouselCard9}`,
  `${CarouselCard10}`,
  `${CarouselCard11}`,
  `${CarouselCard12}`,
  `${CarouselCard13}`,
  `${CarouselCard14}`,
  `${CarouselCard15}`,
  `${CarouselCard16}`,
  `${CarouselCard17}`,
  `${CarouselCard18}`,
  `${CarouselCard19}`
];
class Collection extends React.Component {
  render() {
    return (
      <SubCollection
        noImage
        startImage={HeroCard}
        textSection="When William Shakespeare said “the eyes are the window to your soul” he was only repeating what many before him noticed and many after him felt. 
            It wouldn’t come as a surprise that the eye inspired many jewelry designers through cultures and timelines... from a symbol of the evil eye to an actual portal and parameter of beauty, everyone has his own first thought when hearing the word “eye”, and hence the artistic creations inspired by it are infinite. For me, the eyes are mirrors to the inner person; his heart and soul. They hide a lot but can reveal more, they can lie yet also expose the deepest of secrets, they can give a mysterious glance or be windows to infinite worlds. As small as it is, the eye hides the most and is the gateway to someone’s deepest most complicated personality. 
            Materials used in this special collection are diamonds, semi precious gems and beads, enamel and 18K gold, all together for a funky casual everyday look but also for special delicate occasions. The gems used can be customized to fit your persona. By choosing to use your birthstone or by using gems with sources of energy or healing for a more zen aura, you will be transforming the eye"
        imgUrls={imgUrls}
      >
        <ItemCard image={Card1}></ItemCard>
        <ItemCard image={Card2}></ItemCard>
        <ItemCard image={Card3}></ItemCard>
        <ItemCard image={Card4}></ItemCard>
        <ItemCard image={Card5}></ItemCard>
        <ItemCard image={Card6}></ItemCard>
        <ItemCard image={Card7}></ItemCard>
        <ItemCard image={Card8}></ItemCard>
        <ItemCard image={Card9}></ItemCard>
      </SubCollection>
    );
  }
}
export default Collection;
